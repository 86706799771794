* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f5f5f5;
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  min-height: 100vh;
  background-image: url('/public/bg.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
}

.App-header, main {
  width: 80%;
  max-width: 1200px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.App-header {
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-container input {
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 20px 0 0 20px;
  width: 70%;
  max-width: 300px;
  outline: none; /* 移除默认的焦点���廓 */
  transition: border-color 0.3s ease; /* 添加过渡效果 */
}

.search-container input:focus {
  border-color: #FFD700; /* 使用黄色作为焦点边框颜色 */
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.2); /* 添加柔和的阴影效果 */
}

.search-container button {
  padding: 10px 20px;
  background-color: #FFD700;
  color: #333;
  border: 2px solid #FFD700;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.search-container button:hover,
.search-container button:focus {
  background-color: #FFC000;
  border-color: #FFC000;
}

main {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1200px;
}

.movie-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.movie-item:hover {
  transform: translateY(-5px);
}

.movie-item img {
  width: 100%;
  height: auto;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.movie-item h3 {
  padding: 10px;
  margin: 0;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-detail {
  position: relative;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.movie-detail h2 {
  margin: 0;
  padding: 10px 15px;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;
  color: #000;
}

.player {
  margin-bottom: 20px;
}

.episodes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.episodes button {
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.episodes button:hover,
.episodes button.active {
  background-color: #FFD700;
  color: #333;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #155db1;
}

.loading, .error {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin-top: 20px;
}

.error {
  color: #d93025;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
  padding: 20px;
}

.movie-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.movie-item:hover {
  transform: translateY(-5px);
}

.movie-item img {
  width: 100%;
  height: auto;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.movie-item h3 {
  padding: 10px;
  margin: 0;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-detail {
  position: relative;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.movie-detail h2 {
  margin: 0;
  padding: 10px 15px;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;
}

.player {
  margin-bottom: 20px;
}

.episodes {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.episodes button {
  padding: 8px 12px;
  background-color: #e0e0e0; /* 更深的灰色背景 */
  color: #333; /* 更深的文字颜色 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.episodes button:hover {
  background-color: #d0d0d0;
}

.episodes button.active {
  background-color: #ff6b6b;
  color: white;
}

.episodes button.active:hover {
  background-color: #ff5252;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .App-header, main {
    width: 95%;
    padding: 15px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  .search-container {
    margin-bottom: 15px;
  }

  .search-container input {
    padding: 8px 12px;
    font-size: 14px;
    width: 60%;
  }

  .search-container button {
    padding: 8px 12px;
    font-size: 14px;
  }

  main {
    padding: 10px;
    width: 95%;
  }

  .movie-item {
    padding: 8px;
    margin-bottom: 8px;
  }

  .episodes {
    gap: 5px;
  }

  .episodes button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .player-container {
    margin-bottom: 10px;
  }

  .player-wrapper {
    height: 0;
    padding-bottom: 56.25%; /* 16:9 比例 */
    max-height: none;
  }

  .dplayer {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: none;
  }

  .dplayer-video {
    width: 100%;
    height: 100%;
    max-height: none;
  }

  video {
    width: 100%;
    height: auto;
  }

  .search-results {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
  }

  .movie-item {
    font-size: 12px;
  }

  .movie-item h3 {
    padding: 8px;
    font-size: 12px;
  }
}

.movie-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
}

.movie-header h2 {
  margin: 0;
  font-size: 18px;
}

.share-button {
  position: absolute;
  top: 20px;
  right: 15px;
  background-color: #1e90ff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.share-icon {
  margin-right: 5px;
}

.share-button:hover {
  background-color: #1a7ae1;
}

.player-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  background: #000;
  aspect-ratio: 16/9;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
}

.loading-spinner {
  font-size: 1.2em;
}
.dplayer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.dplayer-video {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
}

.dplayer.dplayer-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.dplayer.dplayer-fullscreen .dplayer-video {
  height: 100%;
  max-height: none;
}

.type-filter {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.type-filter button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.type-filter button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.type-filter button.active {
  background: #1890ff;
}

.logonavbar {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 20px;
  margin-bottom: 20px;
}

.nav-item {
  position: relative;
  padding: 10px 20px;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.nav-link a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.nav-link img {
  width: 12px;
  height: 12px;
  transition: transform 0.3s;
}

.nav-item:hover .nav-link img {
  transform: rotate(180deg);
}

.nav-item:hover .nav-drop {
  display: block;
}

.nav-drop {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  min-width: 150px;
  border-radius: 4px;
  padding: 10px 0;
  z-index: 1000;
}

.dropitem {
  padding: 5px 15px;
}

.dropdown-item {
  color: white;
  text-decoration: none;
  font-size: 14px;
  display: block;
  padding: 5px 0;
}

.dropdown-item:hover {
  color: #1890ff;
}

/* 调整搜索框位置 */
.search-container {
  margin-top: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  padding: 1rem;
}

.pagination button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #FFD700;
  color: #333;
  border: 2px solid #FFD700;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button:hover:not(:disabled) {
  background-color: #FFC000;
  border-color: #FFC000;
  transform: translateY(-1px);
}

.pagination button:disabled {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
  opacity: 0.7;
}

.pagination span {
  color: white;
  font-size: 14px;
  padding: 0 10px;
  min-width: 80px;
  text-align: center;
}

.nav-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  text-align: left;
  width: 100%;
}

.nav-button:hover {
  color: #1890ff;
}

.nav-button.dropdown-item {
  font-size: 14px;
  padding: 5px 0;
}

/* 添加以下移动端导航样式 */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  /* background-color: rgba(0, 0, 0, 0.8); */
}
.header-top h1 {
  flex: 1;
  text-align: center;
  margin: 0;
}
.menu-toggle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}
.placeholder {
  width: 40px;
}

.hamburger {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  position: relative;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: white;
  left: 0;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
}

/* 列表页样式 */
.list-page {
  padding: 20px;
}

/* 详情页样式 */
.movie-detail {
  padding: 20px;
}

.movie-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.movie-poster {
  flex: 0 0 200px;
}

.movie-poster img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.movie-meta {
  flex: 1;
  min-width: 300px;
  color: #333;
}

.movie-meta p {
  margin: 8px 0;
  line-height: 1.5;
}

.episodes-container{
  margin-top: 10px;
  color: #333;
}

.App-footer {
  width: 95%;
  max-width: 1200px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  margin: 0 10px;
  border-radius: 4px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #ffd700; /* 黄色 */
}

.source-tabs {
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

.source-tabs button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
}

.source-tabs button span{
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 3px;
  color: #fff
}

.source-tabs button.active {
  color: #FFD700;
  border-bottom: 2px solid #FFD700;
}

.dplayer-pip-icon {
  padding: 7px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
}

.dplayer-pip-icon svg {
  width: 22px;
  height: 22px;
}

.dplayer-pip-icon:hover {
  opacity: 0.85;
}

.announcement-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.announcement-modal {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: #ff0000;
}

.announcement-modal h2 {
  color: #ff0000;
  margin-bottom: 20px;
}

.announcement-content {
  text-align: left;
  margin: 20px 0;
}

.announcement-content p {
  margin: 10px 0;
  color: #ff0000;
  line-height: 1.5;
}

.announcement-btn {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.announcement-btn:hover {
  background-color: #ff7875;
}

/* 桌面端样式 */
@media (min-width: 769px) {
  .menu-toggle {
    display: none;
  }
  
  .mobile-nav {
    display: flex;
    flex-direction: column;
  }
  
  .logonavbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* 移动端样式 */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  
  .mobile-nav {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
  }
  
  .mobile-nav.open {
    display: block;
  }
  
  .logonavbar {
    flex-direction: column;
    width: 100%;
  }
  
  .nav-item {
    width: 100%;
  }
  
  .nav-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  
  .nav-button {
    width: 100%;
    text-align: left;
    padding: 12px 0;
  }
  
  .nav-drop {
    position: static;
    width: 100%;
    display: block;
    background-color: rgba(50, 50, 50, 0.5);
  }
  
  .dropitem .nav-button {
    padding-left: 20px;
  }
  
  .search-container {
    padding: 15px;
    display: flex;
  }
  
  .search-container input {
    flex: 1;
    padding: 8px;
  }
  
  .search-container button {
    white-space: nowrap;
    padding: 8px 15px;
  }

  .movie-info {
    flex-direction: column;
  }
  .player-wrapper {
    padding-top: 56.25%; /* 保持 16:9 比例 */
  }
  
  .player-wrapper .video-js {
    height: 100% !important;
  }

  .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
    padding-top: 56.25% !important;
  }
  .footer-links {
    gap: 10px;
  }
  
  .footer-link {
    padding: 0px;
    font-size: 12px;
    white-space: nowrap;
  }

  .source-tabs {
    margin-bottom: 0.5rem;
    border-bottom: 0.5px solid #eee;
    padding-bottom: 0.5rem;
  }
  
  .source-tabs button {
    font-size: 14px;
    margin-right: 0.5rem;
    padding: 0.5rem 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #666;
  }
  .pagination button {
    padding: 2px;
    font-size: 12px;
  }
  .pagination span {
    font-size: 12px;
  }
  .source-tabs button span{
    position: absolute;
    top: -4px;
    right: -4px;
    font-size: 8px;
    padding: 2px;
    border-radius: 3px;
    color: #fff
  }
}